import React from 'react';

const services = [
  {
    id: 1,
    image: '/img/renta1.jpg',
    title: 'Renta corto plazo',
    description: 'Lo rentamos por día; Para quienes necesitan el uso del equipo de manera temporal en expos, conferencias, congresos, capacitaciones o cualquiera que sea su evento.',
    link: '#',
  },
  {
    id: 2,
    image: '/img/renta2.jpg',
    title: 'Renta largo plazo',
    description: 'Es la mejor estrategia fiscal y tecnológica ya que es 100% deducible de impuestos. Obtienes el equipo con la mejor tecnología de punta con una mínima inversión',
    link: '#',
  },
  {
    id: 3,
    image: '/img/renta3.jpg',
    title: 'Renta sector educativo',
    description: 'Soluciones de arrendamiento de equipo multifuncional exclusivas para aulas de  educación y equipo administrativo de alto rendimiento, para cualquier proyecto educativo',
    link: '#',
  },
];

const Shadow = () => {
  return (
    <div className="container">

      <div className="row">
        {services.map((service) => (
          <div className="col-md-4 mb-4" key={service.id}>
            <div className="card shadow">
              <img src={service.image} className="card-img-top" alt={service.title} />
              <div className="card-body">
                <h5 className="card-title">{service.title}</h5>
                <p className="card-text">{service.description}</p>
                <a href={service.link} className="btn btn-primary">Leer más</a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Shadow;
