import React from 'react';
import Header from '../organisms/Header';
import Hero from '../molecules/Hero';
import Features from '../molecules/Features';
import Testimonials from '../molecules/Testimonials';
import Footer from '../organisms/Footer';
import './HomePage.css';
import Body from '../organisms/Body';
import ScrollTop from '../atoms/ScrollTop';
import '../atoms/ScrollTop.css'

const HomePage = () => {
  return (
    <div className="home-page">
      <Header />
      <Hero />
      <Body />
      <br/>
      <Footer />
      <ScrollTop />
    </div>
  );
};

export default HomePage;
