import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Hero.css';

const Hero = () => {
  return (
    <section className="hero">
      <Container>
        <Row>
          <Col>
            <h1>¡Bienvenido a Multiservicios Kalu!</h1>
            <p>Tu socio ideal para el éxito.</p>
            {/* <Button variant="primary" size="lg">Leer más</Button> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
