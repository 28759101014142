import React, { useState } from 'react';
import { Carousel, Container, Image } from 'react-bootstrap';
import './Slide.css';

const Slide = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, event) => {
    setIndex(selectedIndex);
  };

  return (
    
    <Container className="mt-5">
      <Carousel>
        <Carousel.Item>
          <Image src="/img/slide1_.jpg" alt="Image 1" className="d-block w-100" />
          <Carousel.Caption>
            <h3>Renta y venta de equipos multifuncionales</h3>
            <p>A color y monocromaticos.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Image src="/img/slide2_.jpg" alt="Image 2" className="d-block w-100" />
          <Carousel.Caption>
            <h3>Slide 2</h3>
            <p>This is the second slide of the carousel.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Image src="/img/slide3_.jpg" alt="Image 3" className="d-block w-100" />
          <Carousel.Caption>
            <h3>Slide 3</h3>
            <p>This is the third slide of the carousel.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </Container>
  );
};

export default Slide;
