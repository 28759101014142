import React from 'react';
import { Container, Row, Col, Card, Image, Button, Grid } from 'react-bootstrap';
import './Services.css';
import Shadow from '../atoms/Shadow';
import PorQueRentar from './PorQueRentar';
import PasosCompra from './PasosCompra';

const Services = () => {

  return (
    <>
    <div className="text-center">
    <h1>Servicios:</h1>
    </div>
    <hr className="hr-custom" />
    <div className="parallax">
      <div className="parallax-text">
        <h1 style={{
          color: '#FEA301',
          fontWeight: 'bold',
          fontSize: '50px',
          textShadow: '4px 4px 4px rgba(0, 0, 0)',
          margin: 0,
          padding: 0
        }}>
          Renta de multifuncionales
        </h1>
        <h3 className="text-light">Impulsar y materializar tus ideas nunca fue tan facil</h3>
      </div>
    </div>
    <div className="parallax2">
      <div className="parallax-text">
        <h1 style={{
          color: '#E92AC2',
          fontWeight: 'bold',
          fontSize: '50px',
          textShadow: '4px 4px 4px rgba(0, 0, 0)',
          margin: 0,
          padding: 0
        }}>
          Venta de equipo de computo empresarial
        </h1>
        <h3 className="text-light">Hacemos que la tecnología evolucione con tu negocio</h3>
      </div>
    </div>
    <div className="parallax3">
      <div className="parallax-text">
        <h1 style={{
          color: '#04578D',
          fontWeight: 'bold',
          fontSize: '50px',
          textShadow: '4px 4px 4px rgba(0, 0, 0)',
          margin: 0,
          padding: 0
        }}>
          Videovigilancia
        </h1>
        <h3 className="text-light">Un paso adelante para tu seguridad</h3>
      </div>
    </div>
    <br/>
    <div className="text-center">
    <h1>Nuestras modalidades:</h1>
    </div>
    <hr className="hr-custom" />
    <div className="text-center"><h5>Brindamos un servicio integral en renta de equipos multifuncionales para tu empresa</h5></div>
    <br/>
    <Shadow/>
    <br/>
    <Col>
    <PorQueRentar/>
    <div className="text-center voletar">
        <i className="bi bi-triangle-fill" Style="color:rgb(4, 87, 141)" fontSize="32"></i>
    </div>
    </Col>
    <br/>
    <PasosCompra/>
    </>
  );
};

export default Services;
