import React from 'react';
import './Body.css';
import Slide from '../molecules/Slide';
import Services from '../molecules/Services';

const Body = () => {
  const year = new Date().getFullYear();
  return (
    <>
    <div className="grid">
        <Slide />
        <br />
        <Services />
    </div>
    </>
  );
};

export default Body;