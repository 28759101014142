import React from 'react';
import './PasosCompra.css';
import { Container, Row, Col, Card, Image, Button, ListGroup, ListGroupItem } from 'react-bootstrap';

const PasosCompra = () => {
    return (
        <>
        <Container>
        <div className="text-center">
            <h1>Amplía, renueva o modifica la tecnología de tu empresa en cualquier momento</h1>
        </div>
        <hr className="hr-custom" />
        <div className="text-center"><h5>Toda la capacidad de tecnología trabajando para ti</h5></div>
        <div className="text-center"><img className="diagrama" src="/img/proceso-renta-multi.webp" /></div>
        <br/>
        <ListGroup className="list-group">
      <ListGroupItem variant="primary" className="d-flex align-items-center">
        <span className="rounded-circle bg-primary text-white fs-3 fw-bold">1</span>
        <span className="flex-fill ms-3">Solicitud de cotización</span>
        <p className="mt-2">Te brindamos asesoría personalizada para elegir el equipo que mejor cubra tus necesidades con la flexibilidad que requieres</p>
      </ListGroupItem>
      <ListGroupItem variant="secondary" className="d-flex align-items-center">
        <span className="rounded-circle bg-secondary text-white fs-3 fw-bold">2</span>
        <span className="flex-fill ms-3">Solicitud de documentos</span>
        <p className="mt-2">Disponemos de un equipo analista que estudia la viabilidad económica, para minimizar riesgos, impagos o fraudes.</p>
      </ListGroupItem>
      <ListGroupItem variant="success" className="d-flex align-items-center">
        <span className="rounded-circle bg-success text-white fs-3 fw-bold">3</span>
        <span className="flex-fill ms-3">Firma de contrato</span>
        <p className="mt-2">Disponemos de contratos electrónicos, así agilizamos papeleos y gestiones.</p>
      </ListGroupItem>
      <ListGroupItem variant="info" className="d-flex align-items-center">
        <span className="rounded-circle bg-info text-white fs-3 fw-bold">4</span>
        <span className="flex-fill ms-3">Pago y entrega de los equipos</span>
        <p className="mt-2">Realiza el pago de acuerdo a tu contrato, entregamos los equipos en el lugar pactado.</p>
      </ListGroupItem>
      <ListGroupItem variant="warning" className="d-flex align-items-center">
        <span className="rounded-circle bg-warning text-white fs-3 fw-bold">5</span>
        <span className="flex-fill ms-3">Recolección y/o renovación</span>
        <p className="mt-2">Una vez finalizado el contrato de arrendamiento de tus equipos, te ofrecemos diferentes alternativas. </p>
      </ListGroupItem>
    </ListGroup>
        </Container>
        </>
    );
};

export default PasosCompra;