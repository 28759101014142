import React from 'react';
import './PorQueRentar.css';

const PorQueRentar = () => {
  return (
    <div className="container-fluid mt-5 contenedor_ text-light">
      <div className="row">
        <div className="col-12 text-center">
          <h1 className="text-center mb-4">¿Por qué rentar con Multiservicios Kalu?</h1>
          <hr className="hr-custom" />
          <h5>Renta multfuncionales en Playa del Carmen, Cancún y Tulum</h5>
        </div>
      </div>
    <br/>
      <div className="row justify-content-center d-flex">  {/* Contenedor para las cuatro tarjetas */}
        <div className="col-md-4">
          <div className="card shadow text-center mb-4">
            <i className="bi bi-palette2 icono_"></i>
            <div className="card-body">
              <h5 className="card-title">Amplia variedad de opciones</h5>
              <p className="card-text">Encontrarás la propuesta de renta perfecta para tus necesidades.</p>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card shadow text-center mb-4">
            <i className="bi bi-shield-plus icono_"></i>
            <div className="card-body">
              <h5 className="card-title">Seguridad y protección</h5>
              <p className="card-text">Tus intereses de negocios están en buenas manos con nosotros.</p>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card shadow text-center mb-4">
            <i className="bi bi-tools icono_" fontSize="32"></i>
            <div className="card-body">
              <h5 className="card-title">Servicio de mantenimiento completo</h5>
              <p className="card-text">Nos encargamos de todo, desde el mantenimiento hasta las reparaciones.</p>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card shadow text-center mb-4">
            <i className="bi bi-cash-coin icono_"></i>
            <div className="card-body">
              <h5 className="card-title">Precios competitivos</h5>
              <p className="card-text">Obtén la mejor relación calidad-precio en el mercado.</p>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card shadow text-center mb-4">
            <i className="bi bi-headset icono_"></i>
            <div className="card-body">
              <h5 className="card-title">Asesoramiento comercial</h5>
              <p className="card-text">Te brindamos asesoría especializada para el equipo que mejor cubra tus necesidades</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PorQueRentar;
