import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import './Header.css';

const Header = () => {
  return (
    <Navbar bg="light" expand="lg" className="header">
      <Container>
        <Navbar.Brand href="#home">
          <img src="/img/logo_blanco.png" width={50} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end"> {/* Apply flexbox justification */}
          <Nav> {/* Navigation links */}
            <Nav.Link href="#home">Inicio</Nav.Link>
            <Nav.Link href="#about">Sobre nosotros</Nav.Link>
            <Nav.Link href="#services">Servicios</Nav.Link>
            <Nav.Link href="#contact">Contacto</Nav.Link>
          </Nav>
          <Button variant="success" href="https://api.whatsapp.com/send?phone=529841968674">
            <i className="bi-whatsapp"></i> Contáctanos
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
