import React from 'react';
import { Container, Row, Col, Navbar, Nav, Image, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Importar Link para navegación interna
import './Footer.css';

const Footer = () => {
  return (
    <footer className="bg-dark text-light py-5">
      <Container>
        <Row>
          <Col md={4}>
            <Image src="/img/logo_blanco (2).png" alt="Logo de la empresa" className="mb-3" width={200}/>
            <p className="lead">Desbloquee un mundo de posibilidades comerciales con los Equipos informáticos empresariales duraderos y de alto Rendimiento de Multiservicios Kalu.</p>
            <p>
              <a href="tel:+529841968674" className="text-light">
                <i className="fas fa-phone me-2"></i>
                +52 984-196-8674
              </a>
            </p>
            <p>
              <a href="mailto:contacto@multiservicioskalu.mx" className="text-light">
                <i className="fas fa-envelope me-2"></i>
                contacto@multiservicioskalu.mx
              </a>
            </p>
          </Col>
          <Col md={4}>
          <h5 className="mb-4">Síguenos en las redes sociales</h5>
            <ul className="list-inline">
              <li className="list-inline-item me-3 icon">
                <a href="https://www.facebook.com/MSKaluOficial" target="_blank" className="text-light">
                <i class="bi bi-facebook icono_ icon"></i>
                </a>
              </li>
              <li className="list-inline-item icon">
                <a href="https://www.instagram.com/multiservicioskalu/" target="_blank" className="text-light">
                <i class="bi bi-instagram icono_ icon"></i>
                </a>
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <h5 className="text-uppercase mb-3">Suscríbete</h5>
            <Form className="d-flex">
              <Form.Control type="email" placeholder="Ingrese su correo electrónico" className="me-2" />
              <Button variant="primary">Suscribirse</Button>
            </Form>
          </Col>
        </Row>
        <hr className="mt-4" />
        <Row className="text-center">
          <Col>
            <p className="mb-0">&copy; 2024 Multiservicios Kalu. Todos los derechos reservados.</p>
            <p>
              
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
