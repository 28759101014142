import ThemeProvider from 'react-bootstrap/ThemeProvider';
import { Button } from 'react-bootstrap';
import { RotatingLines } from 'react-loader-spinner';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

/* MySwal.fire({
  title: <p>Hello World</p>,
  didOpen: () => {
    // `MySwal` is a subclass of `Swal` with all the same instance & static methods
    MySwal.showLoading()
  },
}).then(() => {
  return MySwal.fire(<p>Shorthand works too</p>)
}) */

function App() {
  return (
    <>
      <Button className="btn btn-success" id="macarios" onClick={()=>{alert('sapos y culebras')}}>Hola mundo</Button>

      <RotatingLines
      visible={true}
      height=""
      width="96"
      color="grey"
      strokeWidth="5"
      animationDuration="0.75"
      ariaLabel="rotating-lines-loading"
      wrapperStyle={{}}
      wrapperClass=""
      />
    </>
  );
}

export default App;